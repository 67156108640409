import React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import SEOMetaTags from "../components/seo/seo";
import GetIntlSinglePrimitive from "../utils/GetIntlSinglePrimitive";

export default function NotFoundPage() {
  const intl = useIntl();
  const pageTitle = GetIntlSinglePrimitive(intl, "notfound.pageTitle");
  return (
    <Layout>
      <SEOMetaTags title={pageTitle} />
      <div className="not-found-page">
        <h1>
          <FormattedMessage id="notfound.title" />
        </h1>
        <p>
          <FormattedMessage id="notfound.text" />
        </p>
      </div>
    </Layout>
  );
}
